<template lang="">
    <div class="search-page">
        <div class="box-search">
            <Search />
        </div>
        <div v-if="!isSearching">
            <h2>{{ $t('search_page.search_result_for') }}: {{ this.$route.query.keyword }}</h2>
            <p>{{ total }} {{ $t('search_page.result') }}</p>
            <a-empty :description="$t('no_data')" v-if="!total" />
            <CourseList :list="courses" v-if="total" />
            <div class="more" @click="handleLoadMore" v-if="!isLoadMore && total && total > courses.length">
                <button>{{ $t('load_more') }}</button>
            </div>
        </div>
        <Loading v-if="isSearching || isLoadMore" />
    </div>
</template>

<script>
import Loading from '../../components/Web/Loading/Loading.vue';
import Search from '../../components/Web/Search/Search.vue';
import CourseList from '../../components/Web/Courses/CourseList.vue';
import * as CourseAPI from '../../api/courseAPI';

export default {
    components: {
        Loading,
        Search,
        CourseList,
    },

    data() {
        return {
            isSearching: false,
            isLoadMore: false,
            total: 0,
            currentPage: 1,
            courses: [],
        };
    },

    mounted() {
        this.getCourses(1);
    },

    watch: {
        '$route.query': function () {
            this.getCourses(1);
        },
    },

    methods: {
        async getCourses(page) {
            try {
                page === 1 ? (this.isSearching = true) : (this.isLoadMore = true);
                const keySearch = this.$route.query?.keyword?.trim() || '';
                const res = await CourseAPI.search({
                    fields: ['$all'],
                    where: {
                        $or: [{ title: { $iLike: `%${keySearch}%` } }, { array_hashtag: { $contains: [keySearch] } }],
                    },
                    order: [['created_at_unix_timestamp', 'desc']],
                    limit: 12,
                    page,
                });
                this.total = res?.pagination?.total;
                this.courses =
                    page === 1 ? res?.results?.objects?.rows : this.courses.concat(res?.results?.objects?.rows);
                setTimeout(() => {
                    page === 1 ? (this.isSearching = false) : (this.isLoadMore = false);
                }, 500);
            } catch (error) {
                page === 1 ? (this.isSearching = false) : (this.isLoadMore = false);
            }
        },

        handleLoadMore() {
            const nextPage = this.currentPage + 1;
            this.currentPage = nextPage;
            this.getCourses(nextPage);
        },
    },
};
</script>

<style scoped lang="scss">
.search-page {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    margin: 80px auto;

    & h2 {
        margin-bottom: 10px;
        font-size: 24px;
    }

    & p {
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: 500;
    }
}

.no-data {
    margin-top: 100px;
    font-size: 20px;
    text-align: center;
}

.box-search {
    display: none;
    margin: 20px 0;
}

.more {
    margin-top: 60px;
    text-align: center;

    & button {
        padding: 14px 38px;
        background-color: #000;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        border-radius: 4px;
    }
}

@media screen and (max-width: 767px) {
    .search-page {
        margin: 40px auto;

        & h2 {
            font-size: 20px;
        }
    }

    .box-search {
        display: block;
    }
}
</style>
