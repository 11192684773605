import { render, staticRenderFns } from "./SearchPage.vue?vue&type=template&id=0c59fef9&scoped=true&lang=true&"
import script from "./SearchPage.vue?vue&type=script&lang=js&"
export * from "./SearchPage.vue?vue&type=script&lang=js&"
import style0 from "./SearchPage.vue?vue&type=style&index=0&id=0c59fef9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c59fef9",
  null
  
)

export default component.exports